<template>
  <div class="dashboard">
    <div class="dashboard__container">
      <Loader v-if="(!userProfile || (userProfile && !userProfile.id))" />
      <div class="dashboard__container--header mobile-header-switch pr-0 pl-0 pb-2" v-if="(userProfile  && userProfile.id)">
        <!-- <h1>My Account</h1> -->
        <h1 v-if="isDetails">Account Settings</h1>
        <h1 v-if="isPayroll">Payroll Settings</h1>
        <h1 v-if="isEmployee">Employee Payroll Settings</h1>
        <h1 v-if="isTagline">Tagline</h1>
        <h1 v-if="isSkills">My Job Skills</h1>
        <h1 v-if="isUploads">My Certifications</h1>
        <h1 v-if="isPhotos">My Photos</h1>
        <h1 v-if="isEmergency">Emergency Contacts</h1>
        <h1 v-if="isSettings">My Account</h1>
        <h1 v-if="isID">Upload Your ID</h1>
        <h1 v-if="isResume">Upload Your Resumé</h1>
        <h1 v-if="isHarrassment">The Civil Rights Department Training</h1>
        <button class="btn btn__outlined" v-if="!isPayrollTimesheets && !payrollHub && !isSkills && !isDetails && !isWelcome && !isResume" @click="goBack()">Go Back<i class="fas fa-arrow-left ml-3"></i></button>
       <!--  <span class="hidden-small">
        <span class="flex align-center flex-wrap justify-center">
          
          <router-link :to="{name: 'accountDetails'}">
            <button class="btn  ml-3 mb-3" v-bind:class="{ 'btn__dark': isDetails, 'btn__outlined': !isDetails }">Contact Info</button>
            
          </router-link>
          <router-link :to="{name: 'accountTagline'}">
            <button class="btn  ml-3 mb-3" v-bind:class="{ 'btn__dark': isTagline, 'btn__outlined': !isTagline, 'invalid': !userProfile.tagline }">Tagline</button>
          </router-link>
          <router-link :to="{name: 'accountSkills'}">
            <button class="btn  ml-3 mb-3" v-bind:class="{ 'btn__dark': isSkills, 'btn__outlined': !isSkills, 'invalid': (!userProfile.skills || userProfile.skills.length == 0) }">Skills</button>
          </router-link>
          <router-link :to="{name: 'accountUploads'}">
            <button class="btn  ml-3 mb-3" v-bind:class="{ 'btn__dark': isUploads, 'btn__outlined': !isUploads, 'invalid': !userProfile.id_card_path }">Uploads</button>
          </router-link>
          <router-link :to="{name: 'accountPhotos'}">
            <button class="btn  ml-3 mb-3" v-bind:class="{ 'btn__dark': isPhotos, 'btn__outlined': !isPhotos, 'invalid': !userProfile.photoUrl }">Photos</button>
          </router-link>
          <router-link :to="{name: 'accountEmergency'}">
            <button class="btn  ml-3 mb-3" v-bind:class="{ 'btn__dark': isEmergency, 'btn__outlined': !isEmergency, 'invalid': (!userProfile.emergency1name && !userProfile.emergency1phone) }">Emergency</button>
          </router-link>
          <router-link :to="{name: 'payrollMenu'}">
            <button class="btn btn__primary ml-3 mb-3">Payroll</button>
          </router-link>

          <router-link :to="{name: 'accountSettings'}">
            <button class="btn  ml-3 mb-3" v-bind:class="{ 'btn__dark': isSettings, 'btn__outlined': !isSettings }">Settings</button>
          </router-link>
        </span>
      </span> -->
        
      </div>
      
        <router-view :key="$route.params.id" />

    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import router from '@/router'

export default {
  name: 'accountHome',
  data() {
     return {
       address: "",
     };
   },
  computed: {
    ...mapState(['userProfile', 'currentUser']),
    isDetails() {
      return this.$route.name == 'accountDetails';
    },
    isSkills() {
      return this.$route.name == 'accountSkills';
    },
    isUploads() {
      return this.$route.name == 'accountUploads';
    },
    isTagline() {
      return this.$route.name == 'accountTagline';
    },
    isID() {
      return this.$route.name == 'accountId';
    },
    isResume() {
      return this.$route.name == 'accountResume';
    },
    isEmergency() {
      return this.$route.name == 'accountEmergency';
    },
    isSettings() {
      return this.$route.name == 'accountSettings';
    },
    isPayroll() {
      return (this.$route.name == 'payrollHome' || this.$route.name == 'payrollOnboarding' || this.$route.name == 'payrollMenu');
    },
    isEmployee() {
      return (this.$route.name == 'employeeHome' || this.$route.name == 'employeeOnboarding' || this.$route.name == 'employeeMenu');
    },
    isPhotos() {
      return this.$route.name == 'accountPhotos';
    },
    isWelcome() {
      return this.$route.name == 'accountWelcome';
    },
    isHarrassment() {
      return this.$route.name == 'sexualHarassment';
    },
    payrollHub() {
      return this.$route.name == 'payrollHub';
    },
    isPayrollTimesheets() {
      return this.$route.name == 'payrollTimesheets';
    },
  },
  components: {
    Loader,
  },
  methods: {
    goBack() {
      router.go(-1)
    },
  }
}
</script>